<template>
  <div>
    <message :data="data" />
    <div
      class="cb-message parcel-selected"
      :class="{'is-bot' : data.isBot}">
      <span
        ref="icon"
        class="cb-message-icon">
        <avatar-bot />
      </span>
      <div
        ref="text"
        class="cb-message-text">
        <span class="time-hour">{{ timeHour }}</span>
        <v-card class="elevation-0">
          <v-toolbar
            dark
            color="channelSms"
            height="60"
            flat>
            <v-avatar
              color="grey lighten-4"
              size="80">
              <img
                src="@/assets/images/fake/senac.jpg"
                :alt="`Logo da empresa ${company.fantasyName}`">
            </v-avatar>
          </v-toolbar>

          <v-toolbar-title class="darkGray--text text-center mx-0 mb-3">
            {{ company.fantasyName }}
          </v-toolbar-title>
          <v-layout>
            <v-flex
              xs12
              md6
              class="">
              <span>Valor Original</span>
            </v-flex>
            <v-flex
              xs12
              md6
              class="text-right">
              {{ $n(parcelSelected.originalValue, 'currency') }}
            </v-flex>
          </v-layout>
          <v-divider class="my-2" />
          <v-layout
            v-for="parcel in parcelSelected.details"
            :key="parcel.type"
            class="details">
            <v-flex
              xs12
              md6
              class="">
              <span>{{ $t('calculateParams.' + parcel.type) }}</span>
            </v-flex>
            <v-flex
              xs12
              md6
              class="text-right">
              {{ $n(parcel.value, 'currency') }}
            </v-flex>
          </v-layout>
          <v-divider class="my-2" />
          <v-layout>
            <v-flex
              xs12
              md6
              class="">
              <span>Total</span>
            </v-flex>
            <v-flex
              xs12
              md6
              class="text-right">
              <span>{{ $n(parcelSelected.currentValue, 'currency') }}</span>
            </v-flex>
          </v-layout>
          <v-divider class="my-2" />
          <v-layout>
            <v-flex
              xs12
              md6
              class="">
              <b>Parcelamento</b>
            </v-flex>
            <v-flex
              xs12
              md6
              class="text-right">
              <b>{{ parcelSelected.parcel }}x {{ $n(parcelSelected.parcelValue, 'currency') }}</b>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Message from './Message'
  import chatUiMixin from '@/mixins/chat-ui'
  import proposal from '../mocks/proposal'
  import AvatarBot from '../AvatarBot'

  export default {
    components: { Message, AvatarBot },
    mixins: [ chatUiMixin ],
    computed: {
      parcelSelected () {
        let index = this.data.options.value - 1
        return proposal.installments[index]
      },
      company () {
        return proposal.company
      }
    },
    mounted () {
      this.iconAnimation()
      this.messageAnimation()
    }
  }
</script>

<style lang="sass">
  .parcel-selected
    // opacity: 0
    // scale: 0
    display: flex
    align-items: flex-start

    .cb-message-text
      margin-top: 30px
      font-size: 14px
      width: 350px

    .v-toolbar__title
      padding-top: 50px

    .v-card
      color: $textColor

      .v-avatar
        position: absolute
        bottom: -35px
        left: 50%
        transform: translateX(-50%)
        box-shadow: 0px 0px 0px 4px $bgColor, 0px 0px 0px 8px $light

      .layout
        margin: 3px

      .details:last-child
        border-bottom: 1px solid $gray

  @media screen and (max-width: $max-mobile)
    .parcel-selected
      .cb-message-text
        max-width: 90%
        width: 90%
</style>
